import type { Order } from '@commercelayer/sdk';
import type { SelectOption } from '@sumup/circuit-ui';
import queryString from 'query-string';

import type { ProductInfo } from 'checkout/hooks/useLineItems';
import type { Product } from 'productSelection/types/products';
import type { Channel } from 'shared/constants/Channel';
import { getCountryCodeFromISOLocale } from 'shared/i18n/helpers';
import * as clOrderAPI from 'shared/infra/commerceLayer/orders';
import { isQuotesChannel } from 'shared/services/channel';
import logger from 'shared/services/logger';
import type { LoggerContext } from 'shared/services/logger/logger';
import { retrieveGlobalLocale } from 'shared/services/OrderInformationService.globals';
import { dispatchUnavailableProductRemovalEvent } from 'shared/services/tracker/events';
import type { UnavailableProductRemovalEventData } from 'shared/services/tracker/types';
import type { OrderLineItem } from 'shared/store/order/types';
import { createSKUCode, type SKUCode } from 'shared/types/ids';
import type { Maybe } from 'types/util';

const INCLUDED_ORDERED_PRODUCT_TYPES = ['hardware', 'license', 'service'];
const DASHBOARD_URL = process.env.NEXT_PUBLIC_DASHBOARD_URL;

export const SUMUP_ONE_SKU: SKUCode = createSKUCode('SRV-SU1-001');
export const SUMUP_ONE_HARDWARE_MAX_QUANTITY = 1;

export function isSumUpOneInProductList(
  products: Product[] | OrderLineItem[],
): boolean {
  const sumupOneProduct = products.find(
    (product) => product.code === SUMUP_ONE_SKU,
  );

  return !!sumupOneProduct;
}

export type ProductGroup = {
  name: string;
  items: Product[];
};

type ProductMap = {
  [productTypeName: string]: Product[];
};

const filterLineItemsByProductType = (lineItems: Product[]): Product[] =>
  lineItems.filter((p) =>
    INCLUDED_ORDERED_PRODUCT_TYPES.includes(p.productType.identifier as string),
  );

const sortLineItemsByProductType = (lineItems: Product[]): Product[] => {
  const liToSort = [...lineItems];

  liToSort.sort((a, b) => {
    const iA = INCLUDED_ORDERED_PRODUCT_TYPES.indexOf(
      a.productType.identifier as string,
    );
    const iB = INCLUDED_ORDERED_PRODUCT_TYPES.indexOf(
      b.productType.identifier as string,
    );

    return iA - iB;
  });

  return liToSort;
};

const groupLineItemsByProductType = (lineItems: Product[]): ProductGroup[] => {
  const groups = lineItems.reduce(
    (mem: ProductMap, val: Product) => ({
      ...mem,
      [val.productType.name]: [...(mem[val.productType.name] || []), val],
    }),
    {},
  );

  return Object.keys(groups).map((key) => ({
    name: key,
    items: groups[key],
  }));
};

export const getLineItemGroups = (lineItems: Product[]): ProductGroup[] => {
  const filtered = filterLineItemsByProductType(lineItems);
  const sorted = sortLineItemsByProductType(filtered);
  const grouped = groupLineItemsByProductType(sorted);

  return grouped;
};

export const getQuantityOptions = (
  channel: Channel,
  maxPurchaseQuantity: number,
  quotesPurchaseQuantity?: number,
): SelectOption[] =>
  isQuotesChannel(channel)
    ? [
        {
          label: quotesPurchaseQuantity.toString(),
          value: quotesPurchaseQuantity,
        },
      ]
    : [...Array<number>(maxPurchaseQuantity)].map((_, i) => ({
        label: `${i + 1}`,
        value: i + 1,
      }));

export const formatCartExpirationDate = (
  date: string | undefined,
  locale: string,
): Maybe<string> => {
  if (!date) {
    return null;
  }

  const formatted = new Date(date).toLocaleDateString(locale);

  return formatted;
};

export const buildSumUpOneRedirectUrl = (locale: string): string => {
  const lowerCaseLocale = locale.toLowerCase();
  const dashboardSignUpUrl = `${DASHBOARD_URL}/${locale}/signup`;
  const preservedParams = queryString.parse(window.location.search);

  const queryParameters = {
    ...preservedParams,
    fcam_rc: `${lowerCaseLocale}-core-sumup-one-flow`,
    country_hint: lowerCaseLocale,
    force_country: true,
    subscription: 'sumup_one',
  };

  const stringifiedQueryString = queryString.stringify(queryParameters);

  return `${dashboardSignUpUrl}?${stringifiedQueryString}`;
};

export const updateProductQuantity = (
  products: OrderLineItem[],
  ctfProducts: Product[],
  changeLineItemQuantityFn: (
    product: ProductInfo,
    quantity: number,
  ) => Promise<Order>,
  hasSumUpOne: boolean,
): void => {
  if (hasSumUpOne && products.length > 1) {
    products.forEach(async (product) => {
      if (product.quantity > SUMUP_ONE_HARDWARE_MAX_QUANTITY) {
        const productInfo = ctfProducts.find(
          (ctfProduct) => ctfProduct.code === product.code,
        );

        await changeLineItemQuantityFn(
          {
            id: product.id,
            trackingId: productInfo?.trackingId || '',
            code: product.code,
            formattedUnitAmount: product.formattedUnitAmount,
          },
          SUMUP_ONE_HARDWARE_MAX_QUANTITY,
        );
      }
    });
  }
};

export async function removeUnavailableLineItem(
  lineItem: OrderLineItem,
  orderId: string,
): Promise<void> {
  const countryCode = getCountryCodeFromISOLocale(retrieveGlobalLocale());
  const loggingContext: Partial<LoggerContext> = {
    extra: {
      lineItemId: lineItem.id,
      orderId,
      code: lineItem.code,
    },
    tags: {
      countryCode,
      metricName: 'unavailable_product_removed',
    },
  };

  try {
    const trackingData: UnavailableProductRemovalEventData = {
      product: { code: lineItem.code },
      country: countryCode,
      orderId,
    };

    void dispatchUnavailableProductRemovalEvent(trackingData);
    logger.withContext(loggingContext).warn('Removing unavailable product');

    await clOrderAPI.removeLineItem(lineItem.id);
  } catch (err) {
    logger
      .withContext(loggingContext)
      .error(err, 'Unable to remove out of catalog item');
  }
}

export function getContinueShoppingUrl(
  url: string,
  query: Record<string, string | string[]>,
): string {
  const urlObj = new URL(url);
  const queryObj = new URLSearchParams(query as Record<string, string>);

  queryObj.forEach((value, key) => {
    urlObj.searchParams.set(key, value);
  });

  return urlObj.toString();
}
